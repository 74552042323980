.sidebar-container {
    height: 100vh;
    width: 290px;
    background-color: var(--color-container);
    margin-right: 10px;
}


.sidebar-logo-container {
    padding: 20px 0 0px 15px;
    top: 0px;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-container);
}

.shadow-bottom {
    /* margin-top: -.7rem; */
    background: linear-gradient(#fff 41%, hsla(0, 0%, 100%, .11) 95%, hsla(0, 0%, 100%, 0));
    position: fixed;
    z-index: 2;
    height: 50px;
    width: 230px;
    pointer-events: none;
    -webkit-filter: blur(5px);
    filter: blur(5px);
}

.sidebar-logo {
    width: 160px;
}

.sidebar-nav-items-container {
    margin: 30px 0 10px;
    height: calc(100vh - 100px);
    overflow-y: scroll;
}

.sidebar-nav-items {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.sidebar-nav-items>* {
    width: 100%;
    font-weight: 400;
}

.sidebar-nav-item {
    width: 95%;
    height: 40px;
    font-size: 1em;
    list-style-type: none;
    display: flex;
    align-items: center;
    margin: 0 5px;
    padding: 10px 15px;
    cursor: pointer;
}

.sidebar-nav-item span {
    margin-right: 15px;
    transition: transform .2s ease;
}

.sidebar-nav-item:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 5px;
}

.sidebar-nav-item:hover span {
    transform: translateX(3px);
    font-weight: 100;
}

.sidebar-nav-list .label {
    margin: 20px 0 10px 15px;
    list-style-type: none;
    text-transform: uppercase;
    font-size: 12px;
}

/* @media only screen and (max-width: 800px) {
    .sidebar-container {
        display: none;
    }
} */