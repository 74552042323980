.layout {
    display: flex;
}

.layout-container {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
}

.layout-sticky {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--color-bg);
}