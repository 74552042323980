.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    border: 1px dashed var(--color-primary);
    border-radius: 10px;
    padding: 10px 100px;
    margin: 10px 0;
}

.file-upload-container-drag-active {
    background-color: var(--color-bg);
}

.file-upload-title {
    font-size: 16px;
    font-weight: bold;
    margin: 3px;
    color: var(--color-black);
}

.file-upload-subtitle {
    font-size: 14;
    margin: 3px;
    color: var(--color-secondary);
}

.file-upload-input {
    display: none;
}

.file-upload-drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.loader{
    animation: rotate 2s infinite;
}


@keyframes rotate {
    0%   {transform: rotate(0deg);}
    50%  {transform: rotate(180deg);}
    100% {transform: rotate(360deg);}
  }

@media only screen and (max-width: 600px) {
    .file-upload-container {
        padding: 5px;
        margin: 0;
    }
}