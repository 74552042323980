  .checkbox-wrapper .round {
    position: relative;
  }

  .checkbox-wrapper .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    width: 24px;
    display: block;
    text-align: center;
    margin: auto;
  }

  .checkbox-wrapper .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    opacity: 0;
    margin: auto;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .checkbox-wrapper .round input[type="checkbox"] {
    visibility: hidden;
    display: none;
    opacity: 0;
  }

  .checkbox-wrapper .round input[type="checkbox"]:checked + label {
    background-color: #1d52c1;
    border-color: #1d52c1;
    text-align: center;
  }

  .checkbox-wrapper .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    display: table-cell;
    position: relative;
    left: 5px;
  }

  