.setting-panel {
    margin-right: 5px;
}

.setting-list {
    height: 300px;
    /* border: 1px solid black; */
    overflow-y: scroll;
}

.input-panel {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}