.add-page-subcontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-container);
    justify-content: center;
    padding: 10px;
    user-select: none;
}

.full-width{
    width: 100%;
}

.add-page-entity {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.add-page-entity .add-page-form {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.add-page-entity .add-page-form input {
    max-width: 80vw;
}

.add-page-button-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .add-page-entity {
        flex-direction: column;
    }

    .add-page-entity .add-page-form {
        display: flex;
        flex-direction: column;
    }
}