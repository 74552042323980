.table-container{
    padding: 5px 10px;
}

.table-main{
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: thin;
}

.table-all{
    width: 100%;
}

.table-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-title{
    font-size: 27px;
    margin-left: 5px;
}


.table-search{
    display: flex;
    align-items:baseline;
}

.table-search>div{
    margin-right: 10px;
    color: var(--color-secondary-light);
}

.table-search>input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--color-secondary-light);
    outline: none;
    width: 250px;
    max-width: 100%;
}

.table-search>input:focus {
    border: 1px solid var(--color-primary);
}

.table-all{
    border: 1px solid var(--color-secondary-light);
    margin: 20px 0 0;
    border-spacing: 0;
    
}

.table-header{
    cursor: pointer;
    /* border-right: 1px solid var(--color-secondary-light); */
    /* border-bottom: 1px solid var(--color-secondary-light)  */
    border: 1px solid var(--color-secondary-light) ;
    
}

.table-header>*{
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-header-title{
    padding-top: 5px;
    padding-bottom: 5px;
}

.table-header-filter{
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
}

.table-data{
    /* border-top: 1px solid #E4E5F1; */
    border: 1px solid #E4E5F1;
    padding: 5px;
    user-select: text;
    text-align: center;
    width: auto;
    max-width: 200px;
    /* text-align: center; */
    overflow-wrap: break-word;
    color: var(--color-secondary);
}

.table-input{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--color-secondary-light);
    outline: none;
    max-width: 100px;
    margin-right: 5px;
}

.table-input:focus{
    border: 1px solid var(--color-primary);
}

.table-pagination{
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.table-pagination:first-child{
    display: flex;
}

.table-navigation{
    display: flex;
    align-items: center;
    width: 1005;
    margin: 0 10px;
}

.table-navigation>*{
    border: none;
    background: none;
    margin: 0px 10px;
    cursor: pointer;
}

.table-navigation span{
    font-size: 1.8em;
    font-weight: 100;
}

.table-nav-btn-disable>span{
    color: var(--color-secondary-light);
    cursor: default;
}

input {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    border: 0;
  }

@media only screen and (max-width: 800px) {
    .table-top{
        flex-direction: column;
        align-items: flex-start;
    }
    .table-title{
        margin-left: 0;
    }

    .table-search>div{
        display: none;
    }

    .table-pagination{
        flex-direction: column-reverse;
    }
    .table-pagination:first-child{
        flex-direction: column;
    }
}