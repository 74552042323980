.topbar-container {
    width: 99%;
    background-color: var(--color-container);
    border-radius: 10px;
    margin: 7px;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topbar-title {
    font-size: 1.5em;
    user-select: none;
}

.topbar-user {
    display: flex;
    user-select: none;
    display: flex;
    align-items: center;
}

.topbar-user-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.topbar-user-email {
    font-size: 1em;
}

.topbar-user-cred {
    font-size: .8em;
    color: var(--color-secondary-light);
}

.topbar-user-icon img {
    width: 35px;
    margin-left: 10px;
    border-radius: 50%;
    border: 1px solid var(--color-black);
}

@media only screen and (max-width: 600px) {
    .topbar-container {
        flex-direction: column;
    }
}