.list-page-subcontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-container);
    justify-content: center;
    padding: 10px;
    user-select: none;
}

.list-action{
    cursor: pointer;
}