.input-element {
    display: flex;
    flex-direction: column;
    margin: 4px;
    max-width: 100%;
}

.input_label {
    font-size: 14px;
    margin: 5px 5px;
}

.input_box {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--color-secondary-light);
    outline: none;
    max-width: 100%;
}

.input_box_error{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--color-error);
    outline: 1px solid var(--color-error);
    max-width: 100%;
}

.input_box:focus {
    border: 1px solid var(--color-primary);
}

.react-select__control{
    border-radius: 5px !important;
    border: 1px solid var(--color-secondary-light) !important;
    outline: none !important;
    max-width: 100% !important;
}

.react-select__control--is-focused{
    border: none;
}