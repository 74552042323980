.button-container {
    display: flex;
    margin: 5px;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    max-width: 100%;
    user-select: none;
}

.button-container.full-width {
    width: 100%;
}

.button-container.filled-primary {
    background-color: var(--color-primary-light);
    color: var(--color-white);
}

.button-container.filled-danger {
    background-color: var(--color-error);
    color: var(--color-white);
}

.button-container.filled-warning {
    background-color: var(--color-warning);
    color: var(--color-black);
}

.button-container.outlined-primary {
    border: 1px solid var(--color-primary-light);
    color: var(--color-primary-light);
}

.button-container.outlined-danger {
    border: 1px solid var(--color-error);
    color: var(--color-error);
}

.button-container.disabled-style {
    border: 1px solid var(--color-secondary-light);
    color: var(--color-secondary-light);
}

.button-container:hover{
    filter: brightness(110%);
}