@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Round');

:root {

  /*========== LIGHT THEME ==========*/
  --color-primary: #4750D5;
  --color-primary-dark: #4750d56f;
  --color-primary-light: #4a78cc;
  --color-secondary: #625F6E;
  --color-secondary-light: #A6A4B0;

  --color-container: #FFF;
  --color-bg: #F7F7F7;

  --color-info: #00cfe8;
  --color-success: #28c76f;
  --color-warning: #ff9f43;
  --color-warning-alternate: #E3D026;
  --color-error: #ea5455;

  --color-black: #000;
  --color-white: #FFF;

  /*========== DARK THEME ==========*/


  --sidebar-width: 300px;
}

/*========== DARK THEME ==========*/

/* body[data-theme="light"] > *{
  --color-black: #000;
  --color-white: #fff;
  --color-container: #ffffff;
  --color-bg-primary: #f5f5f5;
  --color-bg-toggle: #1e90ff;
}
body[data-theme="dark"] > *{
  --color-black: #fff;
  --color-white: #000;
  --color-container: #283046;
  --color-bg-primary: #171d31;
  --color-bg-toggle: #171e31;
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  /* user-select:none; */
}

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  color: var(--color-black);
}

a {
  color: inherit;
  text-decoration: none;
}


/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: var(--color-container);
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary-light);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  /* background-color: var(--color-primary-light); */
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}