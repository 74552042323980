.breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 20px 0 20px 20px;
    background-color: var(--color-bg);
    z-index: 5;
}

.breadcrumb-container>* {
    margin-right: 15px;
}

.breadcrum-title {
    font-size: 25px;
    color: var(--color-secondary);
}

.breadcrumb-divider {
    font-size: 25px;
    color: var(--color-secondary-light);
    font-weight: 200;
}

@media only screen and (max-width: 600px) {
    .breadcrum-title {
        font-size: 20px;
    }

    .breadcrumb-divider {
        display: none;
    }

    .breadcrumb-path {
        display: none;
    }
}